import { Token } from 'api/template'
import { CSSProperties, FC } from 'react'
import { CheckboxField } from 'ui'
import { cn } from 'utils'
import styles from './page-field.module.scss'

interface Props {
  token: Token
  props?: Token.ConditionalProps
  hasUserData?: boolean
}

export const PageFieldCheckbox: FC<Props> = ({ token, props, hasUserData }) => {
  if (!Token.isCheckbox(token)) return null
  if (props?.hidden) return null
  const isReadOnly = Token.isReadOnly(token)

  const value =
    hasUserData && !isReadOnly ? undefined : props?.value ? props.value : token.checked ?? undefined

  return (
    <CheckboxField
      id={token.token_id}
      name={token.token_id}
      label={token.label}
      labelClassName={styles.label}
      required={token.required}
      className={cn(styles.pagefield, styles.checkbox)}
      validationClassName={styles.error}
      style={{ '--size': token.size } as CSSProperties}
      // readOnly={token.read_only}

      {...(typeof value === 'boolean' ? { defaultValue: value } : {})}
    />
  )
}
