import { UserSignature, userSignature } from 'api/user-signature'
import { FC, useCallback } from 'react'
import { Form, FieldDropzone, SubmitButton, Description } from 'ui'
import { alertErrorMessage, alertSuccessMessage } from 'utils/toast'
import styles from './load.module.scss'

interface Props {
  onSuccess?: (data: { snapshot_id: string; blob: Blob }) => void
  type: UserSignature.Type
}
interface Data {
  file: File | File[]
}
export const SignatureLoad: FC<Props> = ({ type, onSuccess }) => {
  const upload = useCallback(
    async ({ file }: Data) => {
      try {
        const blob = Array.isArray(file) ? file[0] : file
        const { snapshot_id } = await userSignature.upload({
          file: blob,
          type,
        })
        alertSuccessMessage(UserSignature.MSG.ACTION.UPLOAD_SUCCESS)
        onSuccess?.({ snapshot_id, blob })
      } catch (error) {
        alertErrorMessage(error as Error)
      }
    },
    [onSuccess, type],
  )

  return (
    <>
      <Description className={styles.desctiption}>Accept png images up to 5MB</Description>
      <Form onSubmit={upload} data-signature-type={type}>
        <FieldDropzone
          name="file"
          multiple={false}
          entityLabel="file"
          required
          className={styles.dropzone}
          wrapperClassName={styles.wrapper}
          accept="image/png"
        />
        <footer className={styles.footer}>
          <SubmitButton>{UserSignature.MSG.ACTION.UPLOAD}</SubmitButton>
        </footer>
      </Form>
    </>
  )
}
