import { Token } from 'api/template'
import { FC } from 'react'
import { DateField } from 'ui'
import { cn } from 'utils'
import { serverToISODate } from 'utils/date'
import styles from './page-field.module.scss'

interface Props {
  token: Token
  props?: Token.ConditionalProps
  hasUserData?: boolean
}

export const PageFieldDate: FC<Props> = ({ token, props, hasUserData }) => {
  if (!Token.isDate(token)) return null
  if (props?.hidden) return null

  const readOnly = Token.isReadOnly(token)
  const originalValue = token.lock_to_sign_date ? new Date().toISOString() : props?.value
  const value =
    hasUserData && !readOnly
      ? undefined
      : typeof originalValue === 'string'
      ? serverToISODate(originalValue)
      : undefined
  const format = readOnly ? Token.createDateFormatter(token.format) : undefined
  return (
    <>
      <DateField
        id={token.token_id}
        name={token.token_id}
        label={token.label}
        labelClassName={styles.label}
        required={token.required}
        className={cn(styles.pagefield, styles.field, readOnly && styles.hidden)}
        readOnly={Token.isReadOnly(token)}
        validationClassName={styles.error}
        inputClassName={styles.input}
        {...(typeof value === 'string' && value && { defaultValue: value })}
      />
      {format && value && (
        <span
          className={cn(styles.pagefield, styles.static)}
          data-original-value={originalValue}
          data-value={value}
        >
          {format(new Date(value))}
        </span>
      )}
    </>
  )
}
